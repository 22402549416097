import { handleClearCommandsInterrupt } from "../../../apis";
import { showErrorToast, showSuccessToast } from "../../toast/toastType";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  Box,
  Modal,
  Autocomplete,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getLocationListByType, moveToLocation } from "../../../apis";

const MoveToLocationModal = ({
  openMoveToLocationModal,
  setOpenMoveToLocationModal,
  robotId,
  fleetId,
}) => {
  const token = sessionStorage.getItem("token");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 825,
    bgcolor: "#282F42",
    p: 4,
    borderRadius: "11px",
    boxShadow: "0px 0px 20px rgb(0 0 0 / 40%)",
  };

  useEffect(() => {
    if (token) {
      getLocationListByType(fleetId)
        .then((res) => {
          let drop_off_location = [];

          res?.data?.data.map((location) => {
              location.locations.map((loc) => {
                drop_off_location.push({
                  value: JSON.stringify({
                    positionX: loc?.metricPositionX,
                    positionY: loc?.metricPositionY,
                    positionYaw: loc?.metricPositionYaw,
                    locationName: loc.locationName,
                    lat: loc?.latitude,
                    lon: loc?.longitude,
                    alt: loc?.altitude,
                    latitude: loc?.latitude,
                    longitude: loc?.longitude,
                    altitude: loc?.altitude,
                    headingAngle: loc?.headingAngle,
                  }),
                  label: loc.locationName,
                });
                return null;
              });
            return null;
          });

          // console.log(client_location, "CLIENT");
          setDropLocationOptions([
            ...drop_off_location,
            { value: "Custom", label: "Custom" },
          ]);
        })
        .catch((error) => {
          //show toast here
          showErrorToast(error.message);
        });
    }
  }, [fleetId]);
  const [dropLocationOptions, setDropLocationOptions] = useState([
    { value: "Custom", label: "Custom" },
  ]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationName, setLocationName] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [headingAngle, setHeadingAngle] = useState(null);
  const [altitude, setAltitude] = useState(null);

  const handleInputChange = (e) => {
     if(e.target.name === "locationName"){
      setLocationName(e.target.value)
    }
    else if(e.target.name === "latitude"){
      setLatitude(e.target.value)
    }
    else if(e.target.name === "longitude"){
      setLongitude(e.target.value)
    }
    else if(e.target.name === "altitude"){
      setAltitude(e.target.value)
    }
    else if(e.target.name === "heading-angle"){
      setHeadingAngle(e.target.value)
    }
  }
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      let selectedLocationObj = {}
      if(selectedLocation === "Custom"){
        if(!latitude || !longitude || !headingAngle){
          return;
        }
        selectedLocationObj = {locationName,latitude,longitude,altitude,headingAngle}
      }
      else{
        selectedLocationObj = JSON.parse(selectedLocation);
      }
      await moveToLocation(robotId, selectedLocationObj, token);
      setLocationName(null)
      setLatitude(null)
      setLongitude(null)
      setAltitude(null)
      setHeadingAngle(null)
      setSelectedLocation(null)
      setOpenMoveToLocationModal(false);
    } catch (error) {
      showErrorToast(error.message);
    }
  };
  const handleClose = () => {
    setLocationName(null)
    setLatitude(null)
      setLongitude(null)
      setAltitude(null)
      setHeadingAngle(null)
      setSelectedLocation(null)
      setOpenMoveToLocationModal(false)
  }

  return (
    <Modal backdrop="static" open={openMoveToLocationModal}>
      <Box sx={style}>
        <p className="Modal-wrapper-p">Choose Location to send {robotId} to</p>
        <form onSubmit={handleSubmit}>
          <FormControl
            style={{
              backgroundColor: "rgb(28, 30, 43)",
              width: "100%",
              WebkitTextFillColor: "rgb(189, 196, 224) !important",
            }}
          >
            <Autocomplete
              options={dropLocationOptions}
              // getOptionLabel={(option) => option.locationName}
              // value={selectedLocation}
              value = {
                selectedLocation && dropLocationOptions?.length
                  ? dropLocationOptions.find((option) => {
                      return option === selectedLocation;
                    }) || null
                  : null
              }
              onChange={(_, selectedOption) => {
                setSelectedLocation(selectedOption?.value);
              }}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    // Apply styles here
                    color: "rgb(189, 196, 224)",
                    margin: "0",
                    padding: "5px 5px 5px 10px",
                    fontSize: "14px",
                  }}
                />
              )}
            />
          </FormControl>
          {!!selectedLocation && selectedLocation === "Custom" ? (
            <div className="w-full flex flex-col gap-1">
              <label className="!ml-0 font-semibold">Location Name</label>
              <TextField
                id="locationName"
                fullWidth
                value={locationName}
                name="locationName"
                style={{
                  // Apply styles here
                  backgroundColor: "rgb(28, 30, 43)",
                  width: "100%",
                  WebkitTextFillColor: "rgb(189, 196, 224) !important",
                  color: "rgb(189, 196, 224)",
                  margin: "0",
                  fontSize: "14px",
                  border: latitude ? "1px solid rgb(189, 196, 224)" : "1px solid red", 
                }}
                onChange={(e) => handleInputChange(e)}
                // error={
                //   formik.touched.group &&
                //   Boolean(formik.errors.group)
                // }
                // helperText={
                //   formik.touched.group && formik.errors.group
                // }
              />
              <label className="!ml-0 font-semibold">Latitude</label>
              <TextField
                id="latitude"
                fullWidth
                value={latitude}
                name="latitude"
                style={{
                  // Apply styles here
                  backgroundColor: "rgb(28, 30, 43)",
                  width: "100%",
                  WebkitTextFillColor: "rgb(189, 196, 224) !important",
                  color: "rgb(189, 196, 224)",
                  margin: "0",
                  fontSize: "14px",
                  border: latitude ? "1px solid rgb(189, 196, 224)" : "1px solid red", 
                }}
                onChange={(e) => handleInputChange(e)}
                required
                // error={
                //   formik.touched.group &&
                //   Boolean(formik.errors.group)
                // }
                // helperText={
                //   formik.touched.group && formik.errors.group
                // }
              />
              <label className="!ml-0 font-semibold">Longitude</label>
              <TextField
                id="longitude"
                fullWidth
                value={longitude}
                name="longitude"
                style={{
                  // Apply styles here
                  color: "rgb(189, 196, 224)",
                  backgroundColor: "rgb(28, 30, 43)",
                  width: "100%",
                  WebkitTextFillColor: "rgb(189, 196, 224) !important",
                  margin: "0",
                  fontSize: "14px",
                  border: longitude ? "1px solid rgb(189, 196, 224)" : "1px solid red", 
                }}
                onChange={(e) => handleInputChange(e)}
                required
                // onChange={formik.handleChange}
                // error={
                //   formik.touched.group &&
                //   Boolean(formik.errors.group)
                // }
                // helperText={
                //   formik.touched.group && formik.errors.group
                // }
              />
              <label className="!ml-0 font-semibold">Altitude</label>
              <TextField
                id="altitude"
                fullWidth
                value={altitude}
                name="altitude"
                style={{
                  // Apply styles here
                  backgroundColor: "rgb(28, 30, 43)",
                  width: "100%",
                  WebkitTextFillColor: "rgb(189, 196, 224) !important",
                  color: "rgb(189, 196, 224)",
                  margin: "0",
                  fontSize: "14px",
                  border: latitude ? "1px solid rgb(189, 196, 224)" : "1px solid red", 
                }}
                onChange={(e) => handleInputChange(e)}
                // error={
                //   formik.touched.group &&
                //   Boolean(formik.errors.group)
                // }
                // helperText={
                //   formik.touched.group && formik.errors.group
                // }
              />
              <label className="!ml-0 font-semibold">Heading Angle</label>
              <TextField
                id="heading-angle"
                fullWidth
                value={headingAngle}
                name="heading-angle"
                style={{
                  // Apply styles here
                  backgroundColor: "rgb(28, 30, 43)",
                  width: "100%",
                  WebkitTextFillColor: "rgb(189, 196, 224) !important",
                  color: "rgb(189, 196, 224)",
                  margin: "0",
                  fontSize: "14px",
                  border: headingAngle ? "1px solid rgb(189, 196, 224)" : "1px solid red", 
                }}
                onChange={(e) => handleInputChange(e)}
                required
              />
            </div>
          ) : null}
          <div className="Modal-buttons-wrapper pt-3 d-flex-center-center">
            <button type="submit" className="Modal-btn Modal-btn-red">
              {" "}
              Send
            </button>

            <button
              className="Modal-button-Main-close"
              onClick={() => handleClose()}
            >
              {" "}
              close
            </button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default MoveToLocationModal;
