import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SlPower } from "react-icons/sl";
import { showSuccessToast } from "../../toast/toastType";
import { useDispatch } from "react-redux";
import { destroySession } from "../../../redux/actions";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
// import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
  bgcolor: "#282F42",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const ConfirmationModal = memo(({
  header,
  showConfirmationModal,
  setShowConfirmationModal,
  submit,
  data,
}) => {
  const handleClose = (reason) => {
    setShowConfirmationModal(false);
  };

  return (
    <>
      <Modal
        open={showConfirmationModal}
        backdrop="static"
        onClose={handleClose}
      >
        <Box sx={style}>
          <div className="Cancel-order-Modal-Mainwrapper">
            <p>{header}</p>
            <div className="Modal-button-Main-wrapper">
              <span
                className="!text-[#2b2a44] !bg-white Modal-button-Main-btn yes-red-active !cursor-pointer capitalize"
                onClick={() => {
                  setShowConfirmationModal(false);
                  submit(data);
                }}
              >
                <span>Yes</span>
              </span>
              <button
                className="Modal-button-Main-btn capitalize !cursor-pointer"
                onClick={handleClose}
              >
                {" "}
                <span>No</span>
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
});
export default ConfirmationModal;
