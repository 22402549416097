import { Director, View } from "@millicast/sdk";
// import { WHEPClient } from "./whep.js";

const tokenGenerator = (streamAccountId, streamName) =>
  Director.getSubscriber({
    streamName: streamName,
    // streamName: "testingstream",
    // streamName: "OB02221015",
    streamAccountId: streamAccountId,
  }).catch((error) => {
    console.error(error);
  });

const viewer = async (
  video,
  streamLive,
  setStreamLive,
  setViewCount,
  setStreamStats,
  robotId,
  streamId,
  streamView,
  setStreamView,
  rtcPeerConnection,
  setRtcPeerConnection
) => {
  let streamAccountId;
  let streamName;
  if (streamId) {
    [streamAccountId, streamName] = streamId?.split("/");

    streamView = new View(
      robotId,
      // "OB02221015",
      // "testingstream",
      () =>
        tokenGenerator(
          streamAccountId,
          streamName
          // "OB02221015"
          // "testingstream"
        ),
      video,
      true
    );

    setStreamView(streamView);
    // let pc;
  }
  streamView?.on("broadcastEvent", async (event) => {
    const { name, data } = event;

    switch (name) {
      case "active":
        setStreamLive(true);

        // pc = new RTCPeerConnection();

        // pc.addTransceiver("video", {
        //   direction: "recvonly",
        // });

        // pc.addTransceiver("audio", {
        //   direction: "recvonly",
        // });

        // setRtcPeerConnection(pc);

        // //Create whip client
        // const whep = new WHEPClient();

        // //Start publishing
        // await whep.view(
        //   pc,
        //   `https://director.millicast.com/api/whep/rGE9Jy/${robotId}`,
        //   // `https://director.millicast.com/api/whep/rGE9Jy/OB02221015`,
        //   // `https://director.millicast.com/api/whep/rGE9Jy/testingstream`,
        // );

        // const stream = new MediaStream();

        // pc.getReceivers().forEach((r) => {
        //   stream.addTrack(r.track);
        // });

        // // Assign stream to video media element
        // video.srcObject = stream;

        // // Play the stream
        // let playPromise = video.play();
        // if (playPromise !== undefined) {
        //   playPromise
        //     .then(() => {
        //       // console.log("Stream Playing");
        //     })
        //     .catch((error) => {
        //       console.log(error);
        //     });
        // }

        break;

      case "inactive":
        // activeSources.delete(data.sourceId);
        setStreamLive(false);
        break;

      case "viewercount":
        setViewCount(data.viewercount);
        break;

      case "stopped":
        console.log("STOPPED");
        break;

      default:
        break;
    }
  });

  try {
    await streamView?.connect({
      events: ["active", "inactive", "layers", "viewercount", "stopped"],
    });
  } catch (e) {
    await streamView?.reconnect();
  }

  streamView?.webRTCPeer?.on("stats", (stats) => {
    setStreamStats(stats);
  });
  streamView?.webRTCPeer?.initStats();
};

export default viewer;
